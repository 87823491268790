import { useState } from "react";

function Footer() {
  const [showHint, setShowHint] = useState(false);
  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        left: 20,
        height: 40,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <img
        width="30"
        height="30"
        src="/lightbulb.png"
        alt="3-S-IT Logo"
        style={{ paddingTop: 6, paddingRight: 12 }}
        onClick={() => {
          setShowHint(!showHint);
        }}
      />
      {showHint && (
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
            }}
          >
            Tipp: Use QueryParameter <strong>uri</strong> to automatically fill
            out Swagger-UI form field (e.g. {globalThis.location.href}
            ?uri=https://example.3sit.at/swagger.json).
          </span>
        </div>
      )}
    </div>
  );
}

export default Footer;
