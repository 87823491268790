import Swagger from "./SwaggerUi";
import queryString from "query-string";
import { useState } from "react";
import FormUi from "./FormUi";
import Footer from "./Footer";
import Header from "./Header";

function App() {
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  } | null>(null);
  const [swaggerUri, setSwaggerUri] = useState<string | null>(
    queryString.parse(globalThis.location.search).uri?.toString() ?? null
  );

  return (
    <>
      {<Header setCredentials={setCredentials} />}
      {(!swaggerUri || !credentials) && (
        <FormUi
          initialSwaggerUri={swaggerUri}
          setSwaggerUri={setSwaggerUri}
          setCredentials={setCredentials}
        />
      )}
      {swaggerUri && credentials && (
        <Swagger swaggerUri={swaggerUri} credentials={credentials} />
      )}
      {!swaggerUri && !credentials && <Footer />}
    </>
  );
}

export default App;
