import { FormEvent, useState } from "react";

const formWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  maxHeight: 640,
};
const logoStyle = {
  display: "flex",
  alignItems: "center",
  margin: "auto",
  width: 400,
  marginBottom: 32,
};
const formStyle = { display: "flex", flexDirection: "column" as "column" };
const inputTextStyle = {
  border: "1px solid#ccc",
  boxShadow: "none",
  height: 44,
  margin: "0 auto",
  width: "100%",
  maxWidth: 584,
  borderRadius: 4,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  marginBottom: 16,
};
const inputSubmitStyle = {
  border: "none",
  backgroundColor: "#4CAF50",
  color: "white",
  boxShadow: "none",
  height: 44,
  margin: "0 auto",
  width: "100%",
  maxWidth: 624,
  borderRadius: 4,
  fontSize: 18,
  cursor: "pointer",
};

function FormUi(props: {
  initialSwaggerUri: string | null;
  setSwaggerUri: React.Dispatch<React.SetStateAction<string | null>>;
  setCredentials: React.Dispatch<
    React.SetStateAction<{
      username: string;
      password: string;
    } | null>
  >;
}) {
  const [swaggerUri, setSwaggerUri] = useState(props.initialSwaggerUri || "");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    props.setSwaggerUri(swaggerUri);
    props.setCredentials({ username, password });
  };
  const isInitialSwaggerUriProvided = !!(
    props.initialSwaggerUri && props.initialSwaggerUri.length > 0
  );

  return (
    <div style={formWrapperStyle}>
      <div style={{ width: "100%" }}>
        <img style={logoStyle} src="/swagger-logo.png" alt="Swagger Logo" />
        <form style={formStyle} onSubmit={handleSubmit}>
          <input
            style={inputTextStyle}
            autoFocus={!isInitialSwaggerUriProvided}
            placeholder="https://example.3sit.at/swagger.json"
            type="text"
            value={swaggerUri}
            onChange={(e) => setSwaggerUri(e.target.value)}
          />
          <input
            style={inputTextStyle}
            autoFocus={isInitialSwaggerUriProvided}
            placeholder="admin"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            style={inputTextStyle}
            placeholder="password"
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input style={inputSubmitStyle} type="submit" value="Submit" />
        </form>
      </div>
    </div>
  );
}

export default FormUi;
