function Header(props: {
  setCredentials: React.Dispatch<
    React.SetStateAction<{
      username: string;
      password: string;
    } | null>
  >;
}) {
  return (
    <div
      style={{ paddingTop: 10, paddingLeft: 20, cursor: "pointer" }}
      onClick={() => props.setCredentials(null)}
    >
      <img width="80" height="80" src="/dsit-logo.svg" alt="3-S-IT Logo" />
    </div>
  );
}

export default Header;
