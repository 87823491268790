import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

function Swagger(props: {
  swaggerUri: string;
  credentials: { username: string; password: string };
}) {
  const basicAuthCredentials = `${props.credentials.username}:${props.credentials.password}`;
  return (
    <SwaggerUI
      url={props.swaggerUri}
      // Intercept request and attach basic auth header with base64 encoded user and pwd
      requestInterceptor={(req) => {
        if (req.loadSpec) {
          req.headers.Authorization = `Basic ${btoa(basicAuthCredentials)}`;
        }
        return req;
      }}
    />
  );
}

export default Swagger;
